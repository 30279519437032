import React from 'react';

export const Dalat = {
    "name": "Dalat",
    "link": "dalat",
    "country": "Vietnam",
    "continent": "Asia",
    "thumb": "https://live.staticflickr.com/65535/50379675798_252a1d1cde_t.jpg",
    "image": "https://live.staticflickr.com/65535/50379675798_2e10d97d4d_o.jpg",
    "caption": "City Of Eternal Spring",

    "description": "Tucked away in the mountains, at an elevation of 1500 meters above sea level lies Dalat. This city is a resort located on the highlands of South Vietnam. A famous place where the French rulers used to vacation during the French colonial period turned Dalat into a small French Alps town, and nowadays attracts tourists for its great beauty, for the mountain biking and canyoning experiences. Visitors are known to leave this place loaded with gifts, from flowers to coffee and other products of the region.",

    "nrDays": <p>
            Dalat is small city, and can easily be experienced in <b>one day</b>. Although, the magic of the place is in its surroundings, and you will definitely want to bike or trek through some of the landscape.
            <br/><br/>
            With that said, if you are not on a rush, we recommend you spend at least <b>2 to 3 full days</b> and enjoy what this place has to offer.
        </p>,

    "whenVisit": <p>
            Dalat is located in the <i>"highlands"</i> of Vietnam, where the weather is cooler than the rest of country, with averages of <b>20-25 degrees</b> Celsius all your round.
            <br/><br/>
            The weather here makes it a popular retreat for locals, and since the climate is very consistent, you can enjoy Dalat all throughout the year. There really isn't a best time to visit this city.
            <br/><br/>
            Still, if you don't fancy many tourist or local travelers around, the months between <b>October</b> and <b>March</b> are when you will find the city less crowded.
        </p>,

    "mobility": <p>
            This is a small town, and you can explore the attractions on the city by walking. There are also loads of motorcycle taxis around town if you ever feel tired of walking.
            <br/><br/>
            To explore some of the landscape around, you will want to either <b>rent a motorbike</b>, or if you are not confident enough, take an <b>Easy Rider Tour</b>. You will find lots of options around the city, one of the most famous is <a href="https://www.tripadvisor.com/Attraction_Review-g293922-d1459454-Reviews-Easy_Riders_Vietnam-Da_Lat_Lam_Dong_Province.html" target="_blank" rel="noopener noreferrer">Easy Riders Vietnam</a>.
            <br/><br/>
            Dalat has an <b>airport with daily flights</b> from <a href="/travel-guides/asia/vietnam/hanoi">Hanoi</a> and <a href="/travel-guides/asia/vietnam/ho-chi-minh-city">Ho Chi Minh City</a>, and it's the fastest option to get to the city.
            Otherwise, you can catch the <b>bus</b> from <a href="/travel-guides/asia/vietnam/ho-chi-minh-city">Ho Chi Minh City</a>. The journey takes around 8 hours and costs 11$, and because of that, we recommend you take the <b>night bus</b>.
        </p>,

    "safety": <p>
            Dalat is a relatively peaceful place. But as in any other place where you go during your travels, always pay close attention to your belongings.
        </p>,

    "itinerary": {
        "description": <p>
            Despite being a small town, you will find plenty to do around the countryside of Dalat. The scenery is amazing and some of the activities you can do here will surely please you.
            <br/><br/>
            Although, not included on this itinerary, but a great experience on its own, is to do a <b>Canyoning Tour</b> around Dalat. You will find plenty of companies offering this experiences, and it is one of the most memorable things you can do in Dalat.
        </p>,
        "days": [
            {
                "description": <p>
                    The first day should be dedicated to walking around the city centre of Dalat. Start the day with a visit to the <b>Dalat Market</b>, this place comes alive in the morning and is ideal to grab breakfast and taste some local coffee.
                    After breakfast, is time to start discovering this town. In the middle of the city you will find the <b>Ho Xuan Huong Lake</b>, it is a beautiful lake and you can walk all around it.
                    Doing so will net you a feel of what this town is about, you will pass through some beautiful <b>gardens</b> and <b>parks</b> and get some great views of the city.
                    <br/><br/>
                    In the afternoon, visit some of the most famous places around town and marvel at the French architecture of some of the buildings. You can start by visiting the <b>Crazy House</b>, it's quite a unique place, the only downside is the usual queue at the entrance.
                    Next you can visit the <b>Domain de Marie</b>, a Catholic Church built by the French Colonials. Close by you will find another colonial building, the <b>St. Nicholas Cathedral</b>.
                    For some architecture wonders, visit the summer residence of the last Vietnamese emperor, the <b>Bao Dai Summer Palace III</b>. Close by you will also find the <b>Pasteur Institute</b>.
                    As a final stop, visit the <b>Dalat Railway Train Station</b>, although it is no longer functioning, it serves as a tourist spot now.
                    <br/><br/>
                    At night be sure to pay a visit to the <b>Duong Len Trang</b>, most commonly known as the <i>"The Maze Bar"</i>. As the name suggests, it's a bar built like a maze, with so many small rooms connected by tunnels that you may get lost on your away to the top. It's a fun and unique experience.
                </p>,
                "image": "https://live.staticflickr.com/65535/50503114067_49a7d92f24_o.jpg",
                "caption": "Ho Xuan Huong Lake."
            },
            {
                "description": <p>
                        It's time to explore the countryside of Dalat. This is the real gem of this place and you will pass across some amazing scenery. You can do it by joining an organized tour or <b>rent a motorbike</b> and go on your own.
                        You can start the day with a drive to <b>Sunny Farm Dalat</b>, a complex area with lots of cafés where you can get your breakfast. Here you also find the <b>Stairway To Heaven</b>, a very picturesque staircase and famous among tourists. A few kilometers away is the <b>Linh Phuoc Pagoda</b>, a beautiful Buddhist shrine.
                        <br/><br/>
                        For the rest of the day you will be visiting the beautiful <b>Tuyen Lam Lake</b>, the largest lake in the area. The scenery here is gorgeous, and if you drive around the lake you will find many interesting attractions such as the <b>Truc Lam Temple</b> and the <b>Lavenderdalat Gardens</b>.
                        While in the area, make sure to pay a visit to the <b>Datanla Waterfalls</b>, just a short drive from the lake.
                    </p>,
                "image": "http://www.vietdaytrip.com/wp-content/uploads/2018/10/image.png",
                "caption": "Stairway To Heaven - photo from VietDayTrip."
            },
            {
                "description": <p>
                        For the final day, head to what is possibly the most famous attraction in Dalat, the <b>Elephant Waterfalls</b>. It's about a 1 hour drive from the city centre, but it's absolutely worth it.
                        Close by you will also find the <b>Linh An Pagoda</b>, which is worth paying a visit to also.
                    </p>,
                "image": "https://live.staticflickr.com/65535/50502242783_594fecee00_o.jpg",
                "caption": "Elephant Waterfalls."
            }
        ]
    },

    "highlights": [
        {
            "name": "Ho Xuan Huong Lake",
            "description": "This is the lake in the centre of town. Close by you will find many gardens and parks worth a visit.",
            "image": "https://live.staticflickr.com/65535/50502960036_d08cc272d2_o.jpg"
        },
        {
            "name": "Linh Phuoc Pagoda",
            "description": "Referred to as the pagoda of broken glasses, this Buddhist shrine is composed of many mosaics and thousands of small pieces of broken terracotta, porcelain and glass. Inside you will find he heaviest bell in all of Vietnam.",
            "image": "https://live.staticflickr.com/65535/50502960016_4d1888ba0e_o.jpg"
        },
        {
            "name": "Tuyen Lam Lake",
            "description": "This is a man-made lake, and the biggest one in the area. The natural scenery around the lake is charming, and you can do many activities in and around the lake, like canoeing and biking.",
            "image": "https://live.staticflickr.com/65535/50503113887_3d2efe0810_o.jpg"
        },
        {
            "name": "Elephant Waterfall",
            "description": "Undoubtedly, the most famous waterfall in Dalat. This impressive waterfall is great for photos, and as an added bonus you can actually get up close to the falls, a delight to all senses.",
            "image": "https://live.staticflickr.com/65535/50502242658_c44930716b_o.jpg"
        },
        {
            "name": "Dalat Cable Car",
            "description": "This 2.4 kms cable car ride is a great way to view the city of Dalat and surrounding landscape from above. A roundtrip ticket costs around 5$ and goes from Robin Hill to the Truc Lam Monastery and back. You can explore the monastery once you get there.",
            "image": "https://live.staticflickr.com/65535/50502959961_0ab5e48efd_o.jpg"
        }
    ],

    "food": {
        "description": <p>
                Located at an high altitude, Dalat is the ideal place to grow vegetables, and that's why the city is sometimes referred to as <i>"vegetable garden of the south"</i>.
                Dalat is also very famous for its <b>coffee</b>, which is grown locally, and you will find many cafes around town.
                <br/><br/>
                Unlike other places in Vietnam, food stalls on the streets are not very popular in Dalat, maybe due to the cooler weather. As such, finding a good place to eat may prove more difficult.
                Fear not, we will leave here some suggestions of places that will leave you wanting to come back for more.
            </p>,
        "restaurants": [
            {
                "name": "Duong Len Trang",
                "description": <p>
                        Known as <i>"The Maze Bar"</i>, this unique place is like a maze, with many rooms connected by tiny alleys, and is a must have experience on Dalat.
                    </p>,
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50503113827_96e18826f4_o.jpg"
            },
            {
                "name": "Cafe Bar 13",
                "description": "Famous bar in Dalat, with great coffee. Offers both Western and Vietnamese food.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502959916_3fc5b3d666_o.jpg"
            },
            {
                "name": "Hoa Sen",
                "description": "You will find many vegetarian restaurants on Dalat, but none as good as this one. With cheap prices and great food, this is a must try place.",
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50502242563_3a2a7a9946_o.jpg"
            },
            {
                "name": "Nem Nuong Dung Loc",
                "description": <p>Here you must try the <i>Nem Rán</i> (spring rolls), they are considered the best ones on all of Vietnam.</p>,
                "price": 1,
                "image": "https://live.staticflickr.com/65535/50503113727_1489a5e2e8_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
                Being considered a resort town, there are a lot of accommodation options to choose from. You will find many similarities between hotels here with those you can find on the Swiss and French alps, reminiscent of the architecture used by the French colonials.
                <br/><br/>
                We give you some suggestions of good accommodations to stay.
            </p>,
        "budget": [
            {
                "name": "Starhill Hotel",
                "description": "A 3 star hotel with affordable prices. The rooms are spacious and very clean. It is close to the city centre.",
                "image": "https://live.staticflickr.com/65535/50503113672_7f05ff670d_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Ngoc Phat Dalat Hotel",
                "description": "One of the best accommodations located on the city centre. This hotel is a bargain for all you get.",
                "image": "https://live.staticflickr.com/65535/50503113652_788b37c2f2_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Dalat Palace Heritage Hotel",
                "description": "Considered the best hotel in the region, this is a famous place frequented by the colonial French rulers. It is located in the centre of Dalat.",
                "image": "https://live.staticflickr.com/65535/50502959781_63ed639315_o.jpg",
                "link": "/"
            },
            {
                "name": "Terracota Hotel & Resort",
                "description": "Located 5km away from the city, this is a beautiful luxurious resort with amazing views to the surrounding landscape. The resort offers many amenities, like an indoor pool, spa, tennis court, pool table and more.",
                "image": "https://live.staticflickr.com/65535/50502959756_662fdcafec_o.jpg",
                "link": "/"
            }
        ]
    }
}
